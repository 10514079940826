import React from 'react'
import CustomButton from '../../../RadioUI/CustomButton'
import { useSeriesType, useUserSort, useContentType } from '../hooks/seriesHooks'
import SERIES_TYPES from '../helpers/seriesTypes'
import { useDispatch } from 'react-redux'
import {
  toggleSort,
  replacePodcastSeasonEpisodesById,
  replaceSeasonEpisodesById,
  replaceLatestEpisodesById,
  replaceLatestPodcastEpisodesById
} from '../actions'
import { useParams } from 'react-router-dom'
import SORTS from '../helpers/sorts'
import { useShowSeasonNotLatest } from '../hooks/seasonHooks'
import { css } from '@emotion/react'
import SortIcon from '../../../components/SortIcon'
import PropTypes from 'prop-types'

const SortButton = ({ onClick, ...props }) => {
  const params = useParams()
  const dispatch = useDispatch()
  const seriesType = useSeriesType()
  const currentSort = useUserSort()
  const contentType = useContentType()
  const isShowingSeason = useShowSeasonNotLatest()

  if (seriesType !== SERIES_TYPES.STANDARD) {
    return null
  }

  const nextSort = currentSort === SORTS.ASC ? SORTS.DESC : SORTS.ASC
  return (
    <CustomButton
      {...props}
      aria-label={nextSort === SORTS.ASC ? 'Sorter fra eldste til nyeste' : 'Sorter fra nyeste til eldste'}
      fontSize="2xl"
      w="40px"
      h="40px"
      px={2}
      css={css`
        svg {
          transform: ${currentSort === SORTS.ASC ? 'scaleY(-1)' : 'scaleY(1)'};
        }
      `}
      onClick={() => {
        if (isShowingSeason) {
          contentType === 'onDemand'
            ? dispatch(replaceSeasonEpisodesById(params.seriesId, params.seasonId, { sort: nextSort }))
            : dispatch(replacePodcastSeasonEpisodesById(params.seriesId, params.seasonId, { sort: nextSort }))
        } else {
          contentType === 'onDemand'
            ? dispatch(replaceLatestEpisodesById(params.seriesId, { sort: nextSort }))
            : dispatch(replaceLatestPodcastEpisodesById(params.seriesId, { sort: nextSort }))
        }
        dispatch(toggleSort())
        onClick()
      }}
      data-test="sortButton"
    >
      <SortIcon />
    </CustomButton>
  )
}

SortButton.propTypes = {
  onClick: PropTypes.func
}

export default SortButton
