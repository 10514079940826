import React, { useRef, useState } from 'react'
import Text from '../../../RadioUI/Text'
import CustomButton from '../../../RadioUI/CustomButton'
import PropTypes from 'prop-types'
import SubmissionsLoginDialog from '../../../RadioUI/Submissions/SubmissionsLoginDialog'
import SubmissionsAcceptTermsDialog from '../../../RadioUI/Submissions/SubmissionsAcceptTermsDialog'
import SeriesSubmissionsSubmissionDialog from '../../../components/SeriesSubmissions/SeriesSubmissionsSubmissionDialog'
import { useSeriesSubmissionsSettings, useSeriesTitle } from '../hooks/seriesHooks'
import { useHasAcceptedSubmissionTerms, useIsLoggedIn } from '../../../components/Auth/AuthContext'
import Icon from '../../../RadioUI/Icon'
import Portal from '../../../RadioUI/Portal'

const SeriesPageSeriesSubmissions = ({ maxContent = false, seriesId, ...restProps }) => {
  const isLoggedIn = useIsLoggedIn()
  const hasAcceptedSubmissionTerms = useHasAcceptedSubmissionTerms()
  const seriesSubmissionsSettings = useSeriesSubmissionsSettings()
  const seriesTitle = useSeriesTitle()

  const loginDialogRef = useRef()
  const acceptTermsDialogRef = useRef()
  const [submissionDialoHidden, setSubmissionDialoHidden] = useState(true)

  const hanleClick = () => {
    if (!isLoggedIn) {
      loginDialogRef.current.showModal()
    } else if (!hasAcceptedSubmissionTerms) {
      acceptTermsDialogRef.current.showModal()
    } else {
      setSubmissionDialoHidden(false)
    }
  }

  if (
    !seriesSubmissionsSettings ||
    seriesSubmissionsSettings.loading ||
    seriesSubmissionsSettings.error ||
    !seriesSubmissionsSettings.enabled ||
    !seriesSubmissionsSettings.text.enabled
  ) {
    return null
  }

  return (
    <>
      <CustomButton
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        size="md"
        {...(maxContent ? { px: '10px' } : { px: { base: '8px', sm: '10px' } })}
        onClick={hanleClick}
        {...restProps}
      >
        <Icon
          {...(maxContent
            ? { pl: '4px', 'aria-hidden': true }
            : { pl: { base: '0', sm: '4px' }, 'aria-hidden': { base: false, sm: true } })}
          aria-label="Send inn"
          iconId="nrk-new-chat"
        />
        <Text {...(maxContent ? { d: 'inline-block' } : { d: { base: 'none', sm: 'inline-block' } })} px="8px">
          Send inn
        </Text>
      </CustomButton>
      <SubmissionsLoginDialog ref={loginDialogRef} />
      <SubmissionsAcceptTermsDialog ref={acceptTermsDialogRef} />
      <Portal>
        <SeriesSubmissionsSubmissionDialog
          hidden={submissionDialoHidden}
          setHidden={setSubmissionDialoHidden}
          seriesSubmissions={{
            seriesId: seriesSubmissionsSettings.id,
            seriesTitle,
            submissions: {
              text: seriesSubmissionsSettings.text,
              audio: seriesSubmissionsSettings.audio,
              video: seriesSubmissionsSettings.video,
              image: seriesSubmissionsSettings.image
            }
          }}
        />
      </Portal>
    </>
  )
}

SeriesPageSeriesSubmissions.propTypes = {
  maxContent: PropTypes.bool,
  setDialogsOpen: PropTypes.func,
  seriesId: PropTypes.string
}

export default SeriesPageSeriesSubmissions
