import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import Flex from '../../../RadioUI/Flex'
import SeasonDropdownContainer from '../Components/SeasonDropdownContainer'
import SortButton from '../Components/SortButton'
import ExtraMaterialLink from '../ExtraMaterialPage/ExtraMaterialLink'
import useHandleFocus from '../hooks/useHandleFocus'
import SeasonBackLink from '../Components/SeasonBackLink'
import { useHasExtraMaterial } from '../hooks/seriesHooks'
import useSmallScreenToogle from '../hooks/useSmallScreenToogle'
import ExtraMaterialBackLink from '../ExtraMaterialPage/ExtraMaterialBackLink'
import { css } from '@emotion/react'
import EpisodeMenuWrapper from '../Components/EpisodeMenuWrapper'
import { trackSnowplowEvent, spCategory } from '../../../clientMonitoring'
import { useColors } from '../../../RadioUI/theme/Color'
import Box from '../../../RadioUI/Box'
import SeriesPageSeriesSubmissions from './SeriesPageSeriesSubmissions'

const SeriesPageToolbar = forwardRef((props, ref) => {
  const [applySafariWorkaround, setApplySafariWorkaround] = useState(false)
  const params = useParams()
  const location = useLocation()
  const { seasonFocusRef } = useHandleFocus()
  const { showSeasonOnSmall, showEpisodeOnSmall } = useSmallScreenToogle()
  const isOnExtraMaterialPage = !!matchPath(location.pathname, {
    path: `/${params.contentType}/${params.seriesId}/ekstramateriale`
  })

  const hasExtraMaterial = useHasExtraMaterial()

  return (
    <Flex
      id="seasonsToolbar"
      ref={ref}
      pos="sticky"
      top="0"
      zIndex="sticky"
      bg={useColors().main.medium}
      css={css`
        #SeriesPageToolbar__CoreScroll {
          /* Fix for dropdown being overlapped by CoreScroll in Safari iOS
             Release notes for Safari 13 https://developer.apple.com/documentation/safari-release-notes/safari-13-release-notes#Layout-and-Rendering
            indicates that the need for this is going away.
          */
          ${applySafariWorkaround ? '-webkit-overflow-scrolling: auto !important;' : ''}
        }
      `}
    >
      <Flex
        as="ul"
        d={[showSeasonOnSmall ? 'flex' : 'none', showSeasonOnSmall ? 'flex' : 'none', 'flex']}
        flexDir="row"
        alignItems="center"
        overflow="hidden"
      >
        {!isOnExtraMaterialPage && (
          <li style={{ overflow: 'hidden' }}>
            <SeasonDropdownContainer
              onToggle={event => {
                if (!event.target.hidden) {
                  trackSnowplowEvent(spCategory.SeriesPage, 'SeasonPickerTapped')
                }
                setApplySafariWorkaround(!event.target.hidden)
              }}
              mr="2"
              my="4"
              ref={seasonFocusRef}
            />
          </li>
        )}
        {!isOnExtraMaterialPage && (
          <li>
            <SortButton
              my="4"
              mr="2"
              onClick={() => trackSnowplowEvent(spCategory.SeriesPage, 'ReorderButtonTapped')}
            />
          </li>
        )}
        {isOnExtraMaterialPage && (
          <li>
            <ExtraMaterialBackLink my="4" mr="2" />
          </li>
        )}
        {hasExtraMaterial && !isOnExtraMaterialPage && (
          <li>
            <ExtraMaterialLink my="4" />
          </li>
        )}
      </Flex>
      <Flex
        w="full"
        d={[showEpisodeOnSmall ? 'flex' : 'none', showEpisodeOnSmall ? 'flex' : 'none', 'none']}
        justifyContent={['space-between', 'space-between', 'flex-start']}
        alignItems="center"
      >
        <SeasonBackLink my="4">Alle episoder</SeasonBackLink>
        <Box d="flex" alignItems="center">
          <SeriesPageSeriesSubmissions mr="8px" seriesId={params.seriesId} />
          <EpisodeMenuWrapper />
        </Box>
      </Flex>
    </Flex>
  )
})

SeriesPageToolbar.displayName = 'SeriesPageToolbar'

SeriesPageToolbar.propTypes = {
  children: PropTypes.node
}

export default SeriesPageToolbar
