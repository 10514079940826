import React from 'react'
import Flex from '../../../RadioUI/Flex'
import TitleImage from '../Components/TitleImage'
import Box from '../../../RadioUI/Box'
import PageTitle from '../Components/PageTitle'
import ActionsContainer from '../Components/ActionsContainer'
import SeriesFavourite from '../Components/SeriesFavourite'
import SeriesShare from '../Components/SeriesShare'
import SwitchContentTypeButton from '../Components/SwitchContentTypeButton'
import PageDescription from '../Components/PageDescription'
import { useSeriesImage, useSeriesIsLoading, useSeriesSubtitle, useSeriesTitle } from '../hooks/seriesHooks'
import useHandleFocus from '../hooks/useHandleFocus'
import SeriesPageSeriesSubmissions from './SeriesPageSeriesSubmissions'

const SeriesPageHeading = props => {
  const seriesIsLoading = useSeriesIsLoading()
  const seriesTitle = useSeriesTitle()
  const seriesImageUrls = useSeriesImage()
  const seriesSubtitle = useSeriesSubtitle()
  const { seriesFocusRef } = useHandleFocus()
  return (
    <Flex
      alignItems={['start', 'start', 'start', 'flex-end']}
      className="page-head"
      direction={['column', 'column', 'column', 'row']}
      pb="6"
      {...props}
    >
      <TitleImage
        pr={[0]}
        flex={'0 0 auto'}
        src={seriesImageUrls.src}
        srcSet={seriesImageUrls.srcSet}
        isLoading={seriesIsLoading}
      />
      <Box pl={[0, 0, 0, '2.25rem']} pt={[6, 6, 6, 0]} flex="1 1 auto">
        <PageTitle ref={seriesFocusRef} isLoading={seriesIsLoading} data-test="seriesTitle">
          {seriesTitle}
        </PageTitle>
        <ActionsContainer isLoading={seriesIsLoading}>
          <SeriesFavourite />
          <SeriesShare />
          <SeriesPageSeriesSubmissions mr="8px" />
          <SwitchContentTypeButton />
        </ActionsContainer>
        <PageDescription maxW="100%">{seriesSubtitle}</PageDescription>
      </Box>
    </Flex>
  )
}

SeriesPageHeading.propTypes = {}

export default SeriesPageHeading
