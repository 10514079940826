import React from 'react'
import { Link, matchPath, useLocation, useParams } from 'react-router-dom'
import Button from '../../../RadioUI/Button'

const ExtraMaterialBackLink = props => {
  const { prevPathname } = useLocation()
  const params = useParams()
  const shouldUsePrevPathname = !!matchPath(prevPathname, { path: `/${params.contentType}/${params.seriesId}` })
  const to = shouldUsePrevPathname ? prevPathname : `/${params.contentType}/${params.seriesId}`
  return (
    <Button as={Link} type={null} to={to} leftIcon="nrk-arrow-left" {...props}>
      Episoder
    </Button>
  )
}

ExtraMaterialBackLink.propTypes = {}

export default ExtraMaterialBackLink
