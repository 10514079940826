import React from 'react'
import Flex from '../RadioUI/Flex'

const SortIcon = props => {
  return (
    <Flex justifyContent="center">
      <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0.00012207L12 3.90012V6.17242L7 2.92242L7 15.0024L5 15.0024L5 2.92242L0 6.17242V3.90012L6 0.00012207ZM15 17.08V5.00003L17 5.00004V17.08L22 13.83V16.1023L16 20.0023L10 16.1023V13.83L15 17.08Z"
          fill="currentcolor"
        />
      </svg>
    </Flex>
  )
}

export default SortIcon
