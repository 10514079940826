import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import CoreToggle from '@nrk/core-toggle/jsx'
import CoreScroll from '@nrk/core-scroll/jsx'
import { css } from '@emotion/react'
import { useHistory, useParams } from 'react-router-dom'
import _get from 'lodash/get'
import Box from '../../../RadioUI/Box'
import Button from '../../../RadioUI/Button'
import CustomButton from '../../../RadioUI/CustomButton'
import IconButton from '../../../RadioUI/IconButton'
import { useSortedSeasons, useSeriesIsLoading } from '../hooks/seriesHooks'
import { useColors } from '../../../RadioUI/theme/Color'

const scrollButtonHeight = 24
const listItemHeight = 42
const seasonListHeight = 320

const scrollButtonsStyle = css`
  & {
    width: 100%;
    height: ${scrollButtonHeight}px;
    > div {
      justify-content: center;
    }
  }
`

const ariaHidden = { 'aria-hidden': 'true' }

const noop = () => {}

const SeasonDropdownContainer = forwardRef(({ onToggle = noop, ...restProps }, ref) => {
  const history = useHistory()
  const buttonRef = useRef()
  const currentOptionRef = useRef()
  const params = useParams()
  const seasons = useSortedSeasons()
  const colors = useColors()
  const [hidden, setHidden] = useState(true)
  const [scrollState, setScrollState] = useState({ up: null, down: null })
  const seriesIsLoading = useSeriesIsLoading()

  useImperativeHandle(ref, () => {
    return {
      focus: () => {
        buttonRef.current && buttonRef.current.focus && buttonRef.current.focus()
      }
    }
  })

  useEffect(() => {
    if (!hidden && currentOptionRef.current) {
      currentOptionRef.current.focus()
    }
  }, [hidden])

  if (!Array.isArray(seasons) || seasons.length < 2 || seriesIsLoading) return null

  const selectedSeason = params.seasonId
    ? seasons.find(season => season.name === params.seasonId) || _get(seasons, '[0]')
    : seasons.find(season => season.name === null) || _get(seasons, '[0]')

  const calculatedListHeight = listItemHeight * seasons.length
  const showScrollButtons = calculatedListHeight > seasonListHeight
  const actualListHeight = calculatedListHeight > seasonListHeight ? seasonListHeight : calculatedListHeight
  const ariaHiddenScrollUpButton = (!showScrollButtons || !scrollState.up) && ariaHidden
  const ariaHiddenScrollDownButton = (!showScrollButtons || !scrollState.down) && ariaHidden
  return (
    <Box {...restProps}>
      <CustomButton
        ref={buttonRef}
        rounded="md"
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        w="224px"
        maxW="100%"
        lineHeight="unset"
        data-test="seasonDropDown"
        aria-label={`${selectedSeason.title}, Velg sesong`}
      >
        <span key={selectedSeason.title}>{selectedSeason.title}</span>
        {/* key is a workaround for a bug in Safari where it wouldn't rerender when selectedSeason.title changed */}
        <svg
          style={{ marginRight: '-5px' }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 15L17 9L7 9L12 15Z" fill="currentColor" />
        </svg>
      </CustomButton>
      <CoreToggle
        hidden
        data-popup
        onToggle={event => {
          onToggle(event)
          return setHidden(event.target.hidden)
        }}
        onToggleSelect={event => {
          event.target.hidden = true
        }}
      >
        <Box w="3xs" background={colors.main.mediumLight10} position="absolute" top="md" zIndex="dropdown" shadow="2xl">
          <IconButton
            rounded="none"
            size="sm"
            css={scrollButtonsStyle}
            iconId="nrk-chevron-up"
            disabled={!scrollState.up}
            visibility={showScrollButtons ? 'visible' : 'hidden'}
            aria-label="Bla oppover"
            {...ariaHiddenScrollUpButton}
            onClick={event => {
              event.preventDefault()
              scrollState.up()
            }}
          />
          {!hidden && (
            <Box
              as="ul"
              overflow="hidden"
              transform="translate3d(0,0,0)"
              h={`${actualListHeight}px`}
              whiteSpace="nowrap"
              d="block"
            >
              <CoreScroll
                onScrollChange={event => {
                  setScrollState({
                    up: event.target.scrollTop ? () => event.target.scroll('up') : null,
                    down: event.target.scrollBottom ? () => event.target.scroll('down') : null
                  })
                }}
              >
                {seasons.map(({ name, title }) => (
                  <Box as="li" key={name} data-test="dropdownSeason" h={listItemHeight}>
                    <Button
                      rounded="none"
                      w="calc(100% - 2px)"
                      m="px"
                      onClick={event => {
                        const seasonIdUrlPart = name ? `/sesong/${name}` : ''
                        const path = `/${params.contentType}/${params.seriesId}${seasonIdUrlPart}`
                        history.push(path)
                      }}
                      active={name === selectedSeason.name}
                      ref={name === selectedSeason.name ? currentOptionRef : null}
                    >
                      {title}
                    </Button>
                  </Box>
                ))}
              </CoreScroll>
            </Box>
          )}
          <IconButton
            rounded="none"
            css={scrollButtonsStyle}
            iconId="nrk-chevron-down"
            disabled={!scrollState.down}
            size="sm"
            visibility={showScrollButtons ? 'visible' : 'hidden'}
            aria-label="Bla nedover"
            {...ariaHiddenScrollDownButton}
            onClick={event => {
              event.preventDefault()
              scrollState.down()
            }}
          />
        </Box>
      </CoreToggle>
    </Box>
  )
})

SeasonDropdownContainer.propTypes = {
  onToggle: PropTypes.func
}

SeasonDropdownContainer.displayName = 'ForwardedSeasonDropdownContainer'

export default SeasonDropdownContainer
